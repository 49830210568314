.bodyVerificar {
    max-width: 600px;
    font-family: "DM Sans"  !important;
}
.imgVerificar {
    max-width: 200px;
    width: 100%;
}
.title{
    font-family: "DM Sans"  !important;
}
.cardContainer {
    background-color: transparent !important;
    border: 0px !important;
}