.mainValidateFiles{
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
}
.inputValidateFiles{
    display: none
}
.nameFileSelected{
    margin: 10px 0;
    font-family: "DM Sans"  !important;
}
.buttonValidateFiles{
    margin: 10px 0;
    font-family: "DM Sans"  !important;
}
.noSign{
    margin-top: 20px;
    font-family: "DM Sans"  !important;
}
.buttonSeleccionarArchivos{
    font-family: "DM Sans"  !important;
}