.paperClickPdfViewer {
    background-color: rgba(128, 128, 128, 0.5) !important;
    padding: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titlePdfViewer{
    font-family: "DM Sans" !important;
}