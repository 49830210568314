.inputUploadFiles {
    display: none;
}

.typographyFilesSelected {
    margin: 10px 0;
    font-family: "DM Sans" !important;
}

.itemList {
    font-family: "DM Sans" !important;
}

.buttonSeleccionarArchivos {
    font-family: "DM Sans" !important;
}

.buttonEnviarYFirmar {
    font-family: "DM Sans" !important;
    margin-top: 20px;
}

.popperUploadFiles {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}
.tittlePopper{
    font-family: "DM Sans" !important;
}
.divContentPopper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.congrats-popup {
    margin: 15px !important;
    font-family: "DM Sans" !important;
}
.buttonSignUpload{
    font-family: "DM Sans" !important;
}
.popperBody1 {
    margin-Bottom: 10px;
    max-Width: 90%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-family: "DM Sans" !important;
}

.buttonSignUpload {
    margin-right: 20px !important;
    font-family: "DM Sans" !important;
    margin-bottom: 20px;
}

.buttonCopyUpload {
    margin: 10px 5px;
    font-family: "DM Sans" !important;
    margin-bottom: 20px;
}

.popperErrorUpload {
    z-index: 9999;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-width: 300px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    font-family: "DM Sans" !important;
}

.iconButtonPopperErrorUpload {
    position: absolute !important;
    top: 5px;
    right: 5px;
    color: #777777;
    font-family: "DM Sans" !important;
}

.h6UploadPopperError {
    color: #721c24;
    font-family: "DM Sans" !important;
}

.bodyUploadPopperError {
    margin-top: 10px;
    font-family: "DM Sans" !important;;
}
.css-1pxa9xg-MuiAlert-message{
    font-family: "DM Sans" !important;
}