.bodyFirmar {
    max-width: 600px;
    font-family: "DM Sans" !important;
}

.bodySecondFirmar {
    max-width: 600px;
    color: black;
    font-size: 0.9em;
    font-family: "DM Sans" !important;
    font-weight: bold;
}

.cardContainer {
    background-color: transparent !important;
    border: 0px !important;
}

.imgFirmar {
    max-width: 200px;
    width: 100%;
}

.boxFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: "DM Sans" !important;
    color: #1976d2;
}

.cardFooterContent {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
}