.imgNavbarMobile {
    max-width: 197px;
    width: 100%;
}

.buttonNavbar {
    font-family: "DM Sans", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 500 !important;
    font-style: normal !important;
    border-radius: 20px !important;
    padding: 8px 16px !important;
}
.css-10hburv-MuiTypography-root{
    font-family: "DM Sans", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 500 !important;
    font-style: normal !important;
}