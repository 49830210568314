.signature-content {
    color: #333333;
}
.signature-cards-container{
    max-width: 600px;
}
.signature-details {
    margin-top: 10px;
}
.titleSignature{
    font-family: "DM Sans" !important;
}
.sign-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: "DM Sans" !important;
}

.icon {
    margin-right: 8px;
    color: #1976d2; 
}
.text{
    font-family: "DM Sans" !important;
}
.textError{
    font-family: "DM Sans" !important;
    color: red; 
}